
/* language buttons */
.languageBtn3{
    animation: show-language-btn1-animation 1.5s forwards;
    transition: 0.4s;
}
.languageBtn2{
    animation: show-language-btn1-animation 1s forwards;
    transition: 0.4s;
}
.languageBtn1{
    animation: show-language-btn1-animation 0.5s forwards;
    transition: 0.4s;
}
@keyframes show-language-btn1-animation {
    0%{
        opacity: 0;
        margin-bottom: -62px;
    }
    100%{
        margin-bottom: 8px;
        opacity: 1;
    }
}

.languageBtn3Close{
    animation: show-language-btn1-animation-close 0.5s forwards;
    transition: 0.4s;
}
.languageBtn2Close{
    animation: show-language-btn1-animation-close 1s forwards;
    transition: 0.4s;
}
.languageBtn1Close{
    animation: show-language-btn1-animation-close 1.5s forwards;
    transition: 0.4s;
}
@keyframes show-language-btn1-animation-close {
    0%{
        margin-bottom: 8px;
        opacity: 1;
    }
    100%{
        opacity: 0;
        display: none;
        margin-bottom: -62px;
    }
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RubikMedium.ttf");
  font-family: "Rubik";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RubikRegular.ttf");
  font-family: "Rubik";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RubikBold.ttf");
  font-family: "Rubik";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterSemiBold.ttf");
  font-family: "Inter";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RubikSemiBold.ttf");
  font-family: "Rubik";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OpenSansSemiBold.ttf");
  font-family: "Open Sans";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OpenSansMedium.ttf");
  font-family: "Open Sans";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterMedium.ttf");
  font-family: "Inter";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterRegular.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterBold.ttf");
  font-family: "Inter";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RobotoRomanRegular.ttf");
  font-family: "Roboto";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RubikRomanRegular.ttf");
  font-family: "Rubik";
  font-weight: 400;
}

.ql-container.ql-snow {
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: white;
}

.ql-toolbar.ql-snow {
    display: none;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
}
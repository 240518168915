
@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }

  .snowflake {
    pointer-events: none;
    min-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      overflow: hidden;
  }
  
.snow {
    $total: 60;
    position: absolute;
    width: 10px;
    height: 10px;
    color: #c1e7e5;
    
    border-radius: 50%;
    z-index: 9999;

  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: $random-x + $random-offset;
      $random-x-end-yoyo: $random-x + ($random-offset / 2);
      $random-yoyo-time: random_range(30000, 80000) / 100000;
      $random-yoyo-y: $random-yoyo-time * 100vh;
      $random-scale: random_range(8000, 20000) * 0.0001;
      $fall-duration: random_range(18, 40) * 1s;
      $fall-delay: random(30) * -1s;

  
      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
  
        to {
          transform: translate($random-x-end-yoyo, 160vh) scale($random-scale);
        }
      }
    }
  }
  
  .bg-new-year {
    background: url("../../../public/assets/images/new-year.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 70px;
    width: 100px;
    z-index: 9;
  }
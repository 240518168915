@import url('./tailwind.css');

@import url('../node_modules/three-dots/_index.scss');
@import url('./shared/styles/snow.scss');

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Mulish sans-serif';
  font-size: 1.6rem;
}

.MuiPaper-root {
  border-radius: 15px !important;
}

li.MuiButtonBase-root {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 5rem;

  font-size: 1.6rem;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #f2f2f2 inset;
}

.dot-pulse {

  &,
  &::after,
  &::before {
    width: 6px;
    height: 6px;

    color: #607d8b;

    border-radius: 3px;
    background-color: #607d8b;
  }
}

.popup-btn {
  animation: popupBtn 3s 0.2s ease forwards;
  color: red;
}

.css-1p806sr-MuiTypography-root {
  font-size: 16px !important;
  font-weight: 500;
}

@keyframes popupBtn {
  0% {
    transform: 'scale(0)';
    // opacity: 0;
  }

  75% {
    transform: 'scale(1.2)';
  }

  100% {
    transform: 'scale(1)';
    // opacity: 1;
  }
}
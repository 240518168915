.wrapper {
    overflow: hidden;
}

.wrapper .question-container {
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    text-align: left;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    background-color: #f2f2f2;
    border: 1px solid #9e9d9d;
}

.question-container.active {
    background-image: linear-gradient(90deg,
            transparent,
            rgba(0, 0, 0, 0.04),
            transparent);
}

.wrapper .arrow {
    font-size: 2rem;
    transition: 0.5s ease-in-out;
}

.arrow.active {
    rotate: 180deg;
}

.wrapper .answer-container {
    padding: 0 1rem;
    transition: height 0.7s ease-in-out;
    border: 1px solid #9e9d9d;
}

.wrapper .answer-content {
    padding: 1rem 0;
    font-size: 20px;
    font-style: italic;
}
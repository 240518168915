body {
  margin: 0;
  padding: 0;
  font-family: Open sans-serif;
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}

.alice-carousel__dots-item {
  cursor: pointer;
}

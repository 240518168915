.headerStyles {
  
    padding: 10px 5rem;
    display: flex;
    align-items: center;
}


@media only screen and (max-width: 500px) {
    .headerStyles {
        padding: 0px;
    }
}